
const cookieConsentValue = '12-12-2024'

export default {
  data () {
    return {
      opened: false
    }
  },
  mounted () {
    this.readConsent()
  },
  methods: {
    setConsent (val) {
      localStorage.setItem('cookie-consent', `${cookieConsentValue}-${val}`)
      this.readConsent()
    },
    readConsent () {
      const consent = localStorage.getItem('cookie-consent')

      if (!consent) {
        this.opened = true
      } else {
        this.opened = false
        if (consent === `${cookieConsentValue}-true`) {
          this.$gtag()
        }
      }
    }
  }
}
