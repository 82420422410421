
import { mdiDotsVertical, mdiChevronDown } from '@mdi/js'

export default {
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    extended: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronDown
      }
    }
  },
  computed: {
    trameRef () {
      const scopes = { ddt: 'dept', dreal: 'region' }
      const poste = this.$user.profile.poste
      const code = poste === 'ddt' ? this.$user.profile.departement : this.$user.profile.region

      return `${scopes[poste]}-${this.$options.filters.deptToRef(code)}`
    }
  },
  async mounted () {
    await this.$user.isReady
  },
  methods: {
    async signOut () {
      await this.$supabase.auth.signOut()
      this.$router.push('/')
    }
  }
}
