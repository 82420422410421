const middleware = {}

middleware['analytics'] = require('../middleware/analytics.js')
middleware['analytics'] = middleware['analytics'].default || middleware['analytics']

middleware['matomo'] = require('../middleware/matomo.js')
middleware['matomo'] = middleware['matomo'].default || middleware['matomo']

middleware['passwordRecovery'] = require('../middleware/passwordRecovery.js')
middleware['passwordRecovery'] = middleware['passwordRecovery'].default || middleware['passwordRecovery']

export default middleware
