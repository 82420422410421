
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { ValidationProvider } from 'vee-validate'
export default {
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    inputProps: {
      type: Object,
      default () {
        return {
          label: 'Mot de passe'
        }
      }
    }
  },
  data () {
    return {
      icons: { mdiEye, mdiEyeOff },
      showPassword: false,
      password: ''
    }
  },
  watch: {
    password () {
      this.$emit('input', this.password)
    }
  }
}
