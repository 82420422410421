
export default {
  data () {
    return {
      password: '',
      resetToken: '',
      loading: false,
      dialog: false
    }
  },
  mounted () {
    this.$supabase.auth.onAuthStateChange((event) => {
      if (event === 'PASSWORD_RECOVERY') {
        const params = new URLSearchParams(this.$route.hash.split('#')[1])

        this.resetToken = params.get('access_token')
        this.dialog = true
      } else {
        // console.log('other event', event)
      }
    })
  },
  methods: {
    async resetPassword () {
      this.loading = true

      await this.$supabase.auth
        .updateUser({ password: this.password })

      this.loading = false
      this.dialog = false
    }
  }
}
