
export default {
  // head () {
  //   return {
  //     link: [
  //       { rel: 'preload', href: '/_nuxt/assets/fonts/Marianne/fontes_desktop/Marianne-Regular.otf', as: 'font', type: 'font/otf' },
  //       { rel: 'preload', href: '/_nuxt/assets/fonts/Marianne/fontes_web/Marianne-Regular.woff', as: 'font', type: 'font/woff' },
  //       { rel: 'preload', href: '/_nuxt/assets/fonts/Marianne/fontes_web/Marianne-Regular.woff2', as: 'font', type: 'font/woff2' }
  //     ]
  //   }
  // }
}
