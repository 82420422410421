
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'DefaultLayout',
  data () {
    return {
      snackbar: { text: '', val: false }
    }
  },
  async mounted () {
    // TODO: Is not a function  .isReady at init
    if (typeof this.$user.isReady === 'function') {
      await this.$user.isReady.then((resolve, reject) => {
        if (this.$route.path === '/' && this.$user.profile.poste === 'ddt') {
          this.$router.push(`ddt/${this.$user.profile.departement}/collectivites`)
        }
      })
    }

    if (process.browser) {
      const parsed = qs.parse(this.$route.hash.slice(1))
      if (parsed.error) {
        let errorMessage = ''
        if (parsed.error === 'unauthorized_client') {
          errorMessage = 'Le lien de connexion utilisé est expiré. Ce dernier à soit été déjà utilisé, soit est vieux de plus de 1h. Veuillez demander un nouvel envoi de email de  connexion depuis la page de connexion. '
        }
        this.snackbar = {
          val: true,
          text: errorMessage
        }
      }
    }
    // console.log(this.$route.query)
    if (this.$route.query.contact) {
      axios({
        url: '/api/pipedrive/contacted',
        method: 'post',
        data: {
          email: this.$route.query.contact
        }
      })
    }
  }
}
